import React, { useEffect, useState } from "react";
import SearchBanner from "../../components/searchBox";
import PartnerGrid from "../../components/partnerGrid";
import Testimonial from "../../components/slider";
import { getData, postData, postData_ } from "../../services/Api";
import axios from "axios";
import country from '../../data/country.json';
import ContactUs from '../../components/contactUs'

function Index() {

  

  
  const [partnerData, setPartnerData] = useState(null);
  const [category, setCategory] = useState(null);
  const [categorySelected, setCategorySelected] = useState(null);
  const [regionSelected, setRegionSelected] = useState(null);
  const [paginateQuery, setPaginateQuery] = useState(0);
  const [partnerDataCount, setPartnerDataCount] = useState(0);
  const [contactPopup,setContactPopup] = useState()
  const [loading,setLoading] = useState(true)

  const limitSet = 9;

  const fetchPartnerDataFromApi = async () => {
    setLoading(true);
    try {
      // `filter/partner?category=GSI&skip=${
      //   (paginateQuery <= 0 ? 0 : paginateQuery - 1) * 10
      // }&take=${limitSet}&region=AL
      // `
      const mapC =categorySelected?.filter(el => el.isChecked)?.length > 0 ? categorySelected?.filter(el => el.isChecked)?.map(el => el.id) : category?.map(el => el.id) || []
      const mapR = regionSelected?.filter(el => el.isChecked)?.length > 0 ? regionSelected?.filter(el => el.isChecked)?.map(el => el.code) : country?.map(el => el.code) || []
      const search_text = mapR?.[0]; // assuming mapR is an array of objects
const encodedSearchText = encodeURIComponent(search_text);
      const apiData = await getData(
        `partner-search?unique_id=region_4&search_text=${encodedSearchText}&skip=${
          (paginateQuery <= 0 ? 0 : paginateQuery - 1) * 9
        }&take=${limitSet}`
      ,
      {
        "category": mapC,
        "country": mapR
      }
      );
      setPartnerData(apiData);
      setLoading(false)
    } catch (error) {}
  };

  const fetchCategory = async () => {
    
    try {
      const apiData = await getData("partner-categories");
      setCategory(apiData?.data);
    } catch (error) {}
  };

 

  const fetchCount = async () => {
    try {
      const mapC =categorySelected?.filter(el => el.isChecked)?.length > 0 ? categorySelected?.filter(el => el.isChecked)?.map(el => el.id) : category?.map(el => el.id)
        const mapR = regionSelected?.filter(el => el.isChecked)?.length > 0 ? regionSelected?.filter(el => el.isChecked)?.map(el => el.code) : country?.map(el => el.code)
        const search_text = mapR?.[0]; // assuming mapR is an array of objects
const encodedSearchText = encodeURIComponent(search_text);
      const apiData = await getData(`partners/count?unique_id=region_4&search_text=${encodedSearchText}`,
      {
        // "category": mapC,
        "country": mapR
      }
    );
      setPartnerDataCount(apiData?.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchCategory();
  }, []);
  
  useEffect(() => {
    if(category?.length > 0 && country.length > 0)
    {fetchPartnerDataFromApi();
    fetchCount();
    }
  }, [paginateQuery,categorySelected,regionSelected,category]);

  

  return (
    <div>
      <SearchBanner />
      <div>
        <PartnerGrid
          partnerData={partnerData?.data}
          category={category}
          countryOption={country}
          setPaginateQuery={setPaginateQuery}
          totalCount={partnerDataCount?.total}
          paginateQuery={paginateQuery}
          partnerDataCount={Math.ceil(partnerDataCount.total / 9)}
          setCategorySelected={setCategorySelected}
          setRegionSelected={setRegionSelected}
          categorySelected={categorySelected}
          regionSelected={regionSelected}
          loading={loading}
        />
      </div>
      <h1 className="font-[700] text-[42px] text-center mt-[50px] mb-[50px]">
        Hear From Our Partners
      </h1>
      <Testimonial />
      <div className="bg-[#0040DF] py-[60px] mt-[120px]">
        <div className="container mx-auto text-center">
          <h4 className="text-center font-[700] text-[42px] text-white ">
          Ready to join the LeadSquared squad?
            <br /> Join our select partner program
          </h4>

            <a target="_blank" href="https://www.leadsquared.com/partner-sign-up/" className="bg-white text-[#0040DF] px-[30px] py-[9px] text-[18px] inline-block rounded-[3px] mt-8">
              Contact us
            </a>
          
          <ContactUs
           isOpen={contactPopup}
           onRequestClose={()=>setContactPopup(false)}
          />
        </div>
      </div>
    </div>
  );
}

export default Index;
