import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
export default function App() {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    className: "myCustomCarousel",
  };
  return (
    <div className={"container mx-auto my-[30px] rounded-[14px] pl-[20px] sm:pl-[0] pr-[20px] sm:pr-[0]"}>
      <Slider {...settings} style={{ boxShadow: "0px 9px 23px 4px #00000030" }}>
        <div>
          <div className="sm:flex px-[40px] sm:px-[80px]  pt-[30px] pb-[20px] items-center">
            <div className="sm:pr-[60px] sm:w-[34%] sm:border-r border-[#CACACA] border-solid text-center">
              <img src="/images/Rajaram-Khanolkar.jpg" style={{width:"160px",margin:"auto"}} />
              <h4 className="mt-5 mb-2 font-semibold text-[18px]">
              Rajaram Khanolkar
              </h4>
              <p className="text-[#4B6F8B] uppercase font-semibold text-[14px]">
              Head - Software Solution
              </p>
              <img
                src="/images/equinox_logo_.png"
                alt="chlear"
                width={"70px"}
                className="mt-3 mx-auto"
              />
            </div>
            <p className=" sm:pl-[60px] sm:w-[66%] text-[20px] leading-[36px]">
              
“We have onboarded several customers with LeadSquared, and we have seen our customers improve their sales and operational efficiency significantly.”
            </p>
          </div>
        </div>
       
      </Slider>
    </div>
  );
}
