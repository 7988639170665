import React, { useState } from "react";
import Modal from "react-modal";

import "./index.css";
import { postData } from "../../services/Api";

const Index = ({ isOpen, onRequestClose }) => {
  const [formData, setFormData] = useState({
    contact_email_address: "",
    contact_phone_number: "",
    description: "",
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      "name": `${prevData.firstname} ${prevData.lastname}`,
      "user_infoId": 2,
    }));
  };

  const handleSubmit = async (e) => {
    delete formData.firstname;
    delete formData.lastname;
    
    e.preventDefault();
    try {
      const apiData = await postData("/lead", formData);
      // Handle response from API if needed
      console.log(apiData);
      // Close the modal after successful submission
      onRequestClose();
    } catch (error) {
      // Handle error if submission fails
      console.error("Error submitting data:", error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Modal"
      className={{
        base: "modal-base",
        afterOpen: "modal-base_after-open",
        beforeClose: "modal-base_before-close",
      }}
      overlayClassName={{
        base: "overlay-base",
        afterOpen: "overlay-base_after-open",
        beforeClose: "overlay-base_before-close",
      }}
      shouldCloseOnOverlayClick={true}
      closeTimeoutMS={2000}>
      <div className="px-[30px] py-[20px] overflow-auto">
        <h4 className="text-[20px] font-bold text-[#000] mb-5 text-center">
          Request New App
        </h4>
        <span
          className="absolute right-[20px] top-[20px] cursor-pointer"
          onClick={onRequestClose}>
          <img src="/images/close_X.svg" className="w-[15px] h-[15px]" />
        </span>
        <form onSubmit={handleSubmit}>
          <label>
            <input
              type="text"
              className="border border-[#e1e1e1] border-solid w-[100%] h-[45px] mb-5 px-3 text-[#777]"
              placeholder="First Name*"
              id="firstname"
              name="firstname"
              value={formData.firstname}
              onChange={handleChange}
            />
          </label>
          <label>
            <input
              type="text"
              className="border border-[#e1e1e1] border-solid w-[100%] h-[45px] mb-5 px-3 text-[#777]"
              placeholder="Last Name*"
              id="lastname"
              name="lastname"
              value={formData.lastname}
              onChange={handleChange}
            />
          </label>
          <label>
            <input
              type="text"
              className="border border-[#e1e1e1] border-solid w-[100%] h-[45px] mb-5 px-3"
              placeholder="Email*"
              name="contact_email_address"
              id="contact_email_address"
              value={formData.contact_email_address}
              onChange={handleChange}
            />
          </label>
          <label>
            <input
              type="text"
              className="border border-[#e1e1e1] border-solid w-[100%] h-[45px] mb-5 px-3"
              placeholder="Phone Number*"
              name="contact_phone_number"
              id="contact_phone_number"
              value={formData.contact_phone_number}
              onChange={handleChange}
            />
          </label>

          <label>
            <textarea
              className="border border-[#e1e1e1] border-solid w-[100%] h-[100px] mb-5 px-3"
              placeholder="Description*"
              name="description"
              id="description"
              value={formData.description}
              onChange={handleChange}
            />
          </label>
          {/* Similarly, add inputs for other fields (lastname, email, contact, description) */}
          <button
            type="submit"
            className="bg-[#007bff] text-white px-[24px] py-[9px] rounded-[3px] mt-3 text-[14px] w-[100%]">
            Submit
          </button>
        </form>
      </div>
    </Modal>
  );
};

export default Index;
