import React from "react";
import { Link } from "react-router-dom";
import { AppleIcon, FacebookIcon, LinkedinIcon, TwitterIcon } from "./icons";

function Index() {
  return (
    <footer className="bg-[#000920]">
      <div className="container mx-auto text-[15px]">
        <div className="sm:flex py-[30px] sm:py-[70px] px-[20px] sm:px-0">
          <div className="sm:w-[20%]">
            <h4 className="text-white font-semibold mt-[35px] sm:mt-0 mb-4">
              Products
            </h4>
            <ul className="text-[#6F7B9A]">
              <li className="mb-[16px] sm:mb-2">
                <a
                  target="_blank"
                  className="hover:text-[#fff]"
                  href="https://www.leadsquared.com/crm-software/">
                  Sales CRM
                </a>
              </li>
              <li className="mb-[16px] sm:mb-2">
                <a
                  target="_blank"
                  className="hover:text-[#fff]"
                  href="https://www.leadsquared.com/marketing-automation/">
                  Marketing Automation
                </a>
              </li>
              <li className="mb-[16px] sm:mb-2">
                <a
                  target="_blank"
                  className="hover:text-[#fff]"
                  href="https://www.leadsquared.com/mobile-crm/">
                  Mobile CRM
                </a>
              </li>
              <li className="mb-[16px] sm:mb-2">
                <a
                  target="_blank"
                  className="hover:text-[#fff]"
                  href="https://www.leadsquared.com/portals/">
                  Customer Portal
                </a>
              </li>
              <li className="mb-[16px] sm:mb-2">
                <a
                  target="_blank"
                  className="hover:text-[#fff]"
                  href="https://www.leadsquared.com/ace/">
                  Performance Management
                </a>
              </li>
              <li className="mb-[16px] sm:mb-2">
                <a
                  target="_blank"
                  className="hover:text-[#fff]"
                  href="https://www.leadsquared.com/lead-management-system/">
                  Lead Management System
                </a>
              </li>
              <li className="mb-[16px] sm:mb-2">
                <a
                  target="_blank"
                  className="hover:text-[#fff]"
                  href="https://apidocs.leadsquared.com/">
                  API
                </a>
              </li>
              <li className="mb-[16px] sm:mb-2">
                <a
                  target="_blank"
                  className="hover:text-[#fff]"
                  href="https://www.leadsquared.com/developer/lapps/">
                  Dev Platform
                </a>
              </li>
              <li className="mb-[16px] sm:mb-2">
                <a
                  target="_blank"
                  className="hover:text-[#fff]"
                  href="https://help.leadsquared.com/">
                  Help Portal
                </a>
              </li>
            </ul>
          </div>
          <div className="sm:w-[20%]">
            <h4 className="text-white font-semibold mt-[35px] sm:mt-0 mb-4">
              Industries
            </h4>
            <ul className="text-[#6F7B9A]">
              <li className="mb-[16px] sm:mb-2">
                <a
                  target="_blank"
                  className="hover:text-[#fff]"
                  href="https://www.leadsquared.com/higher-education-crm/">
                  Education CRM
                </a>
              </li>
              <li className="mb-[16px] sm:mb-2">
                <a
                  target="_blank"
                  className="hover:text-[#fff]"
                  href="https://www.leadsquared.com/healthcare/hipaa-compliant-healthcare-crm/">
                  Healthcare CRM
                </a>
              </li>
              <li className="mb-[16px] sm:mb-2">
                <a
                  target="_blank"
                  className="hover:text-[#fff]"
                  href="https://www.leadsquared.com/insurance-crm/">
                  Insurance CRM
                </a>
              </li>
              <li className="mb-[16px] sm:mb-2">
                <a
                  target="_blank"
                  className="hover:text-[#fff]"
                  href="https://www.leadsquared.com/banking-crm/">
                  Banking CRM
                </a>
              </li>
              <li className="mb-[16px] sm:mb-2">
                <a
                  target="_blank"
                  className="hover:text-[#fff]"
                  href="https://www.leadsquared.com/lending-crm/">
                  Lending CRM
                </a>
              </li>
              <li className="mb-[16px] sm:mb-2">
                <a
                  target="_blank"
                  className="hover:text-[#fff]"
                  href="https://www.leadsquared.com/real-estate-crm/">
                  Real Estate
                </a>
              </li>
              <li className="mb-[16px] sm:mb-2">
                <a
                  target="_blank"
                  className="hover:text-[#fff]"
                  href="https://www.leadsquared.com/marketplace-crm/">
                  Marketplace CRM
                </a>
              </li>
              <li className="mb-[16px] sm:mb-2">
                <a
                  target="_blank"
                  className="hover:text-[#fff]"
                  href="https://www.leadsquared.com/travel-crm/">
                  Travel CRM
                </a>
              </li>
              <li className="mb-[16px] sm:mb-2">
                <a
                  target="_blank"
                  className="hover:text-[#fff]"
                  href="https://www.leadsquared.com/manufacturing-crm-software/">
                  Manufacturing CRM
                </a>
              </li>
            </ul>
          </div>
          <div className="sm:w-[20%]">
            <h4 className="text-white font-semibold mt-[35px] sm:mt-0 mb-4">
              Resources
            </h4>
            <ul className="text-[#6F7B9A]">
              <li className="mb-[16px] sm:mb-2">
                <a
                  target="_blank"
                  className="hover:text-[#fff]"
                  href="https://www.leadsquared.com/crm/what-is-crm/">
                  What is CRM
                </a>
              </li>
              <li className="mb-[16px] sm:mb-2">
                <a
                  target="_blank"
                  className="hover:text-[#fff]"
                  href="https://www.leadsquared.com/what-is-lead-management/">
                  What is lead management
                </a>
              </li>
              <li className="mb-[16px] sm:mb-2">
                <a
                  target="_blank"
                  className="hover:text-[#fff]"
                  href="https://www.leadsquared.com/what-is-vendor-management/">
                  What is vendor management
                </a>
              </li>
              <li className="mb-[16px] sm:mb-2">
                <a
                  target="_blank"
                  className="hover:text-[#fff]"
                  href="https://www.leadsquared.com/what-is-sales-management/">
                  What is sales management
                </a>
              </li>
              <li className="mb-[16px] sm:mb-2">
                <a
                  target="_blank"
                  className="hover:text-[#fff]"
                  href="https://www.leadsquared.com/case-studies/">
                  Case Studies
                </a>
              </li>
              <li className="mb-[16px] sm:mb-2">
                <a
                  target="_blank"
                  className="hover:text-[#fff]"
                  href="https://www.leadsquared.com/learn/">
                  Guides & Blogs
                </a>
              </li>
              <li className="mb-[16px] sm:mb-2">
                <a
                  target="_blank"
                  className="hover:text-[#fff]"
                  href="https://www.leadsquared.com/compare-crm/">
                  Compare CRM
                </a>
              </li>
              <li className="mb-[16px] sm:mb-2">
                <a
                  target="_blank"
                  className="hover:text-[#fff]"
                  href="https://www.leadsquared.com/crm/glossary">
                  CRM Glossary
                </a>
              </li>
              <li className="mb-[16px] sm:mb-2">
                <a
                  target="_blank"
                  className="hover:text-[#fff]"
                  href="https://www.leadsquared.com/learn/sales/sales-glossary/">
                  Sales Glossary
                </a>
              </li>
            </ul>
          </div>
          <div className="sm:w-[20%]">
            <h4 className="text-white font-semibold mt-[35px] sm:mt-0 mb-4">
              Company
            </h4>
            <ul className="text-[#6F7B9A]">
              <li className="mb-[16px] sm:mb-2">
                <a
                  target="_blank"
                  className="hover:text-[#fff]"
                  href="https://www.leadsquared.com/our-story/">
                  About Us
                </a>
              </li>
              <li className="mb-[16px] sm:mb-2">
                <a
                  target="_blank"
                  className="hover:text-[#fff]"
                  href="https://www.leadsquared.com/partners/">
                  Partners
                </a>
              </li>
              <li className="mb-[16px] sm:mb-2">
                <a
                  target="_blank"
                  className="hover:text-[#fff]"
                  href="https://www.leadsquared.com/news/">
                  Media & News
                </a>
              </li>
              <li className="mb-[16px] sm:mb-2">
                <a
                  target="_blank"
                  className="hover:text-[#fff]"
                  href="https://www.leadsquared.com/careers/">
                  Careers
                </a>
              </li>
              <li className="mb-[16px] sm:mb-2">
                <a
                  target="_blank"
                  className="hover:text-[#fff]"
                  href="https://www.leadsquared.com/contact/">
                  Contact
                </a>
              </li>
            </ul>
          </div>
          <div className="sm:w-[20%]">
            <h4 className="text-white font-semibold mt-[35px] sm:mt-0 mb-4">
              Get in touch
            </h4>
            <ul className="text-[#6F7B9A]">
              <li className="mb-[16px] sm:mb-2">
                <a target="_blank" className="hover:text-[#fff]" href="/">
                  {"(+1) 732-385-3546 (US)"}
                </a>
              </li>
              <li className="mb-[16px] sm:mb-2">
                <a target="_blank" className="hover:text-[#fff]" href="/">
                  {"080-46971075 (India Sales)"}
                </a>
              </li>
              <li className="mb-[16px] sm:mb-2">
                <a target="_blank" className="hover:text-[#fff]" href="/">
                  {"080-46801265 (India Support)"}
                </a>
              </li>
              <li className="mb-[16px] sm:mb-2">
                <a target="_blank" className="hover:text-[#fff]" href="/">
                  {"62-87750-350-446 (ID)"}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="sm:flex justify-between text-white border-b border-b-[#384159] pb-5 px-[20px] sm:px-[0]">
          <div className="flex items-center mb-[30px] sm:mb-0">
            <span className="text-[18px]">Follow us</span>
            <div className="flex pl-3 items-center gap-[15px]">
              <a
                target="_blank"
                href="https://twitter.com/LeadSquared"
                className="border border-[#fff] rounded-[50%] w-[50px] h-[50px] flex items-center justify-center cursor-pointer">
                <TwitterIcon />
              </a>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/leadsquared/"
                className="border border-[#fff] rounded-[50%] w-[50px] h-[50px] flex items-center justify-center cursor-pointer">
                <LinkedinIcon />
              </a>
              <a
                target="_blank"
                href="https://www.facebook.com/LeadSquared"
                className="border border-[#fff] rounded-[50%] w-[50px] h-[50px] flex items-center justify-center cursor-pointer">
                <FacebookIcon />
              </a>
            </div>
          </div>
          <div>
            <div className="flex items-center mb-[30px] sm:mb-0">
              <span className="text-[18px]">Mobile App</span>
              <div className="flex pl-3 items-center gap-[15px]">
                <a
                  target="_blank"
                  href="https://itunes.apple.com/us/app/leadsquared-crm/id1116979453?ls=1&mt=8"
                  className="border border-[#fff] rounded-[50%] w-[50px] h-[50px] flex items-center justify-center cursor-pointer">
                  <AppleIcon />
                </a>
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.leadsquared.nextgen&hl=en"
                  className="border border-[#fff] rounded-[50%] w-[50px] h-[50px] flex items-center justify-center cursor-pointer">
                  <LinkedinIcon />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="sm:flex justify-between items-center py-5 px-[20px] sm:px-0">
          <p className="text-[#6F7B9A] mb-[30px] sm:mb-0">
            © 2024 LeadSquared{" "}
            <a className="pl-4" href="https://www.leadsquared.com/legal/">
              Legal & Compliance
            </a>
          </p>
          <span className="text-[#6F7B9A] border border-[#6B707F] px-4 py-1 inline-flex items-center rounded-[4px]">
            <img src="/images/flagAmerica.svg" />
            <span className="pl-3">English</span>
          </span>
        </div>
      </div>
    </footer>
  );
}

export default Index;
