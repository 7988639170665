import React, { useEffect, useState } from "react";
import StaticPartner from "./partners.json";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";

const Index = ({
  partnerData,
  category,
  countryOption,
  setPaginateQuery,
  partnerDataCount,
  totalCount,
  paginateQuery,
  setCategorySelected,
  setRegionSelected,
  categorySelected,
  regionSelected,
  loading
}) => {
  const navigate = useNavigate();
  const items = StaticPartner;
  const itemsPerPage = 3;
  const [itemOffset, setItemOffset] = useState(0);
  const [activeAccordion, setActiveAccordion] = useState("Partner category");
  const  [activeAccordionRegion,setActiveAccordionRegion] = useState('Region')
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(0);
  const [countryOptionNew, setCountryOptionNew] = useState();
  useEffect(()=>{
    setCountryOptionNew(countryOption)
  },[])
  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
    setPaginateQuery(event.selected + 1);
    setCurrentPage(event.selected);
    window.scrollTo(0, 0);
  };

  const toDetail = (id) => {
    navigate(`partner/${id}`);
  };

  const handleCategoryChange = (id) =>{
    const updatedCheckboxes = (categorySelected || category).map((checkbox) =>
    checkbox.id === id ? { ...checkbox, isChecked: !checkbox.isChecked } : checkbox
  );
  setCategorySelected([...updatedCheckboxes]);
  setItemOffset(0);
  setPaginateQuery(1);
  setCurrentPage(0)
  
  }

  const handleRegionChange = (id) => {
    const updatedCheckboxes = (regionSelected || countryOption).map((checkbox) =>
    checkbox.code === id ? { ...checkbox, isChecked: !checkbox.isChecked } : {...checkbox, isChecked: false}
  );
  setRegionSelected(updatedCheckboxes);
  }

 const handleCountrySearch = (e)=> {
  const searchTerm = e.target.value?.toLowerCase()
  const filteredCountries = countryOption.filter(country => {
    return country.name.toLowerCase().includes(searchTerm);
  });
  setCountryOptionNew(filteredCountries)
  }

  return (
    <div class="container mx-auto">
      <div className="sm:flex sm:w-[95%] ml-auto">
        <div className="sm:w-[20%] sm:px-[0] px-[30px]">
          <div className="border-b border-t border-b-[#E0E0E0] border-solid py-[12px] sm:px-[20px] px-[0]">
            <h5
              className="flex justify-between text-[16px] font-medium items-center"
              onClick={() => {
                setActiveAccordion((prev) =>
                  prev !== "Partner category" ? "Partner category" : false
                );
              }}>
              Partner category
              <img src="/images/downArrow.svg" className="w-[24px] h-[24px]" />
            </h5>
            {activeAccordion === "Partner category" && (
              <ul className="mt-5 max-h-[200px] overflow-y-auto customScroll">
                {category?.map((el) => (
                  <>
                    <li className="mb-2">
                      <label className="flex items-center cursor-pointer p-1">
                        <span className="relative">
                          
                          <input
                            type="checkbox"
                            defaultChecked={categorySelected?.filter(el => el.isChecked)?.map(ele => ele.id)?.includes(el.id)}
                            //onChange={(e)=>{e.target.checked ? setCategorySelected(el?.id) : setCategorySelected(null)}}
                            id={el.id}
                            onChange={()=>handleCategoryChange(el.id)}
                            name={'categoryCheck'}
                            class="peer cursor-pointer appearance-none relative h-4 w-4 border mt-[6px] border-[#909599] bg-white border-solid transition-all checked:border-primary checked:bg-primary rounded-[4px]"
                          />
                          <div class="pointer-events-none absolute top-[15px] left-1/2 -translate-x-1/2 -translate-y-1/2 text-white opacity-0 transition-opacity peer-checked:opacity-100">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-3 w-3"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              stroke="currentColor"
                              stroke-width="1">
                              <path
                                fill-rule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clip-rule="evenodd"></path>
                            </svg>
                          </div>
                        </span>
                        <span className="pl-3 text-dark text-[14px]">
                          {el?.name}
                        </span>
                      </label>
                    </li>
                  </>
                ))}
              </ul>
            )}
          </div>
          <div className="border-b border-b-[#E0E0E0] border-solid py-[12px] sm:px-[20px] px-[0]">
            <h5
              className="flex justify-between text-[16px] font-medium items-center"
              onClick={() => {
                setActiveAccordionRegion((prev) =>
                  prev !== "Region" ? "Region" : false
                );
              }}>
              Region
              <img src="/images/downArrow.svg" className="w-[28px] h-[28px]" />
            </h5>
            {activeAccordionRegion === "Region" && (
              <>
                <label className="mt-3  block">
            <input
              type="text"
              className="border border-[#e1e1e1] border-solid w-[100%] h-[34px] mb-3 px-3 text-[#777]"
              placeholder="Search"
              id="searchCountry"
              name="searchCountry"
            
              onChange={handleCountrySearch}
            />
          </label>
              <ul className="mt-1 max-h-[200px] overflow-y-auto customScroll pr-1">
                {countryOptionNew?.map((el) => (
                  <>
                    <li className="mb-2">
                      <label className="flex items-center cursor-pointer p-1">
                        <span className="relative">
                          <input
                            type="radio"
                            onChange={()=>handleRegionChange(el.code)}
                            name="cradio"
                            id={el.code}
                            // defaultChecked={regionSelected?.filter(el => el.isChecked)?.map(ele => ele.code)?.includes(el.code)}
                            class="peer cursor-pointer appearance-none relative h-4 w-4 border mt-[6px] border-[#909599] bg-white border-solid transition-all checked:border-primary checked:bg-primary rounded-[4px]"
                          />
                          <div class="pointer-events-none absolute top-[15px] left-1/2 -translate-x-1/2 -translate-y-1/2 text-white opacity-0 transition-opacity peer-checked:opacity-100">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-3.5 w-3.5"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              stroke="currentColor"
                              stroke-width="1">
                              <path
                                fill-rule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clip-rule="evenodd"></path>
                            </svg>
                          </div>
                        </span>
                        <span className="pl-3 text-dark text-[14px]">
                          {el?.name}
                        </span>
                      </label>
                    </li>
                  </>
                ))}
              </ul>
              </>
            )}
          </div>
        </div>
        <div className="sm:w-[80%]  sm:px-[0] px-[30px] relative">
          {

            loading && <div className="absolute w-[100%] h-[100%] top-0 bg-white text-center pt-[57px]">Loading...</div>
          }
          <p className="px-8 text-[#666] text-[14px] mb-3">Showing {Math.max(1, Math.min(totalCount === 0 ? 0 : ((paginateQuery - 1) * 9) + 1, totalCount))} - {totalCount < 9 ? totalCount  : Math.min((paginateQuery * 9), totalCount) === 0 ? 9 :Math.min((paginateQuery * 9), totalCount)} of {totalCount}</p>


          {partnerData?.length > 0 ? <ul className="px-[0] sm:px-8 sm:flex flex-wrap gap-[12px]">
            {partnerData?.map((el) => (
              <>
                <li className="boxedGrid cursor-pointer hover:shadow-lg transition-all duration-[0.5s] mb-3 sm:mb-[0]" onClick={() => toDetail((el.name).toLowerCase().trim().replace(' ','_'))}>
                  {el.avatar && (
                    <img
                      src={el.avatar}
                      className="h-[64px] object-contain object-left"
                    />
                  )}
                  <h5 className="text-[#909090] uppercase my-5 font-semibold text-[12px] headingEllipsis">
                    {el.name}
                  </h5>
                  <div>
                    {/* <h6 className="mt-3 mb-2 font-semibold text-[18px]">
                      {el.title}
                    </h6> */}
                    <div className="text-[16px] break-all">
                      <p className="addressElipsis" dangerouslySetInnerHTML={{ __html: el?.description }}>
                        {/* {el.address_line_1} {el.address_line_2} */}
                      </p>
                      {/* <p>{el.website_url}</p>
                      <p>{el.email_address}</p> */}
                    </div>
                  </div>
                </li>
              </>
            ))}
          </ul>
        :
        <p className="text-center mt-[150px] text-[24px] text-[#8a8a8a] mb-5">No partner found</p>  
        
        }
          {partnerDataCount > 1 &&
            <div className="paginateReact mt-8 pt-8 mb-8">
              <ReactPaginate
                breakLabel="..."
                forcePage={currentPage}
                nextLabel="Next"
                onPageChange={handlePageClick}
                pageRangeDisplayed={6}
                pageCount={partnerDataCount}
                previousLabel="Previous"
                renderOnZeroPageCount={true}
              />
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default Index;
