import React, { useState } from "react";

function Index() {
  const [activeHeader, setActiveHeader] = useState("");
  const [subMenuActive, setSubMenuActive] = useState("");
  const [mobileMenuActive,setMobileMenuActive] =useState(false);

  const menuItems = [
    {
      headerName: "Products",
      subMenu: [
        {
          name: "Sales executive suites",
          avatarUrl: "/images/sales_execution_suite.svg",
          innerList: [
            {
              heading: "Software",
              items: [
                {
                  name: "Sales crm software",
                  url: "https://www.leadsquared.com/crm-software/",
                },
                {
                  name: "Application portals",
                  url: "https://www.leadsquared.com/portals/",
                },
                {
                  name: "Call center crms",
                  url: "https://www.leadsquared.com/call-center-sales-crm/",
                },
                {
                  name: "Mobile CRM App",
                  urll: "https://www.leadsquared.com/mobile-crm/",
                },
                {
                  name: "Omnichannel Communication",
                  url: "https://www.leadsquared.com/converse/",
                },
                {
                  name: "Reporting dashboard",
                  url: "https://www.leadsquared.com/sales-reporting/",
                },
              ],
            },
            {
              heading: "Solutions",
              items: [
                {
                  name: "Lead management system",
                  url: "https://www.leadsquared.com/lead-management-system/",
                },
                {
                  name: "Oppurtunity management",
                  url: "https://www.leadsquared.com/opportunity-management-crm/",
                },
                {
                  name: "Sales process automation",
                  url: "https://www.leadsquared.com/sales-automation/",
                },
                {
                  name: "Sales tracking",
                  url: "https://www.leadsquared.com/sales-tracking/",
                },
                {
                  name: "Door to door CRM",
                  url: "https://www.leadsquared.com/door-to-door-crm/",
                },
                {
                  name: "Remote Team management",
                  url: "https://www.leadsquared.com/remote-workforce-management/",
                },
              ],
            },
          ],
        },
        {
          name: "Sales Performance suites",
          avatarUrl: "/images/sales_performance_suite.svg",
          innerList: [],
        },
        {
          name: "Field Operations Suite",
          avatarUrl: "/images/field_operations_suite.svg",
          innerList: [
            {
              heading: "Software",
              items: [
                {
                  name: "Field Sales crm",
                  url: "https://www.leadsquared.com/mobile-crm/",
                },
                {
                  name: "Merchant  onboaring crm",
                  url: "https://www.leadsquared.com/vendor-onboarding-software/",
                },
                {
                  name: "App UI/UX customizer",
                  url: "https://help.leadsquared.com/mobile-home-builder/",
                },
                {
                  name: "Outside Sales crm",
                  url: "https://www.leadsquared.com/outside-sales-crm/",
                },
              ],
            },
            {
              heading: "Solutions",
              items: [
                {
                  name: "Field Force automation",
                  url: "https://www.leadsquared.com/field-force-automation-software/",
                },
                {
                  name: "Collections Management",
                  url: "https://www.leadsquared.com/collections-crm/",
                },
                {
                  name: "Field Force tracking",
                  url: "https://www.leadsquared.com/field-force-tracker/",
                },
                {
                  name: "Event campaign management",
                  url: "https://www.leadsquared.com/btl-marketing-solution/",
                },
                {
                  name: "bancassurance management",
                  url: "https://www.leadsquared.com/bancassurance-sales/",
                },
              ],
            },
          ],
        },
        {
          name: "Marketing Suite",
          avatarUrl: "/images/marketing_powerhouse.svg",
          innerList: [
            {
              heading: "Software",
              items: [
                {
                  name: "Marketing automation",
                  url: "https://www.leadsquared.com/marketing-automation/",
                },
                {
                  name: "Chatbot - website",
                  url: "https://www.leadsquared.com/chatbot/",
                },
                {
                  name: "Chatbot - whatsapp",
                  url: "https://www.leadsquared.com/whatsapp-integration/",
                },
                {
                  name: "Landing pages",
                  url: "https://www.leadsquared.com/responsive-landing-page-builder/",
                },
                {
                  name: "Email campaigns",
                  url: "https://www.leadsquared.com/email-marketing-software/",
                },
              ],
            },
            {
              heading: "Solutions",
              items: [
                {
                  name: "Lead capture automation",
                  url: "https://www.leadsquared.com/lead-capture-automation/",
                },
                {
                  name: "Lead engagement",
                  url: "https://www.leadsquared.com/collections-crm/",
                },
                {
                  name: "BTL Marketing automation",
                  url: "https://www.leadsquared.com/btl-marketing-solution/",
                },
                {
                  name: "Advanced marketing analysis",
                  url: "https://www.leadsquared.com/marketing-analytics/",
                },
              ],
            },
          ],
        },
        {
          name: "Service CRM",
          avatarUrl: "/images/service_cloud.svg",
          innerList: [],
        },
        {
          name: "Developer platforms",
          avatarUrl: "/images/developer_platform.svg",
          innerList: [],
        },
        {
          name: "Flo stack",
          avatarUrl: "/images/flostack.svg",
          innerList: [],
        },
      ],
    },
    {
      headerName: "Industries",
      subMenu: [
        {
          name: "Healthcare",
          avatarUrl: "/images/healthcare.svg",
          innerList: [
            {
              heading: "Segments",
              items: [
                {
                  name: "Hospitals and Clinics",
                  url: "https://www.leadsquared.com/healthcare/hipaa-compliant-healthcare-crm/",
                },
                {
                  name: "Hospice and Palliative Care",
                  url: "https://www.leadsquared.com/healthcare/hospice-crm/",
                },
                {
                  name: "Fertility Clinics",
                  url: "https://www.leadsquared.com/healthcare/fertility-clinic-crm/",
                },
                {
                  name: "Dental Care",
                  urll: "https://www.leadsquared.com/healthcare/crm-for-dental-practices/",
                },
                {
                  name: "Diagnostics Labs",
                  url: "https://www.leadsquared.com/healthcare/medical-crm-software/",
                },
              ],
            },
            {
              heading: "Solutions",
              


              items: [
                {
                  name: "ACQUISITION",
                  type: "heading",
                  url: "",
                },
                {
                  name: "Patient Intake Automation",
                  url: "https://www.leadsquared.com/healthcare/new-patient-acquisition/",
                },
                {
                  name: "Patient Appointment Scheduling",
                  url: "https://www.leadsquared.com/healthcare/patient-appointment-scheduling/",
                },
                {
                  name: "Healthcare Call Center Solution",
                  url: "https://www.leadsquared.com/sales-tracking/",
                },
                {
                  name: "Patient Experience Management",
                  url: "https://www.leadsquared.com/door-to-door-crm/",
                },
                {
                  name: "Self-serve Patient Portals",
                  url: "https://www.leadsquared.com/remote-workforce-management/",
                },
                {
                  name: "OPERATIONS",
                  type: "heading",
                  url: "https://www.leadsquared.com/remote-workforce-management/",
                },
                {
                  name: "EHR Integration",
                  url: "https://www.leadsquared.com/remote-workforce-management/",
                },
                {
                  name: "Physician Empanelment",
                  url: "https://www.leadsquared.com/remote-workforce-management/",
                },
                {
                  name: "Analytics",
                  url: "https://www.leadsquared.com/remote-workforce-management/",
                },
                {
                  name: "Security and Compliance",
                  url: "https://www.leadsquared.com/remote-workforce-management/",
                },
                
                
                
                {
                  name: "MARKETING",
                  type:"heading",
                  url: "https://www.leadsquared.com/remote-workforce-management/",
                },
                {
                  name: "Marketing Automation",
                  url: "https://www.leadsquared.com/remote-workforce-management/",
                },
                {
                  name: "Patient Engagement",
                  url: "https://www.leadsquared.com/remote-workforce-management/",
                },
              ],
            },
          ],
        },
        {
          name: "Education",
          avatarUrl: "/images/education.svg",
          innerList: [
            {
              heading: "Segments",
              
              items: [
                {
                  name: "Higher Education",
                  url: "https://www.leadsquared.com/mobile-crm/",
                },
                {
                  name: "Pre-schools and K12",
                  url: "https://www.leadsquared.com/vendor-onboarding-software/",
                },
                {
                  name: "Training Institutions",
                  url: "https://help.leadsquared.com/mobile-home-builder/",
                },
                {
                  name: "EdTech",
                  url: "https://www.leadsquared.com/outside-sales-crm/",
                },
                {
                  name: "Overseas Education",
                  url: "https://www.leadsquared.com/outside-sales-crm/",
                },
              ],
            },
            {
              heading: "Solutions",
              items: [
                {
                  name: "Student Recruitment Software",
                  url: "https://www.leadsquared.com/field-force-automation-software/",
                },
                {
                  name: "Admission Portal",
                  url: "https://www.leadsquared.com/collections-crm/",
                },
                {
                  name: "Teacher Onboarding",
                  url: "https://www.leadsquared.com/field-force-tracker/",
                },
                {
                  name: "Publisher Portal",
                  url: "https://www.leadsquared.com/btl-marketing-solution/",
                },
                {
                  name: "Admission Software",
                  url: "https://www.leadsquared.com/bancassurance-sales/",
                },
              ],
            },
          ],
        },
        {
          name: "Financial services",
          avatarUrl: "/images/financial_services.svg",
          innerList: [
            {
              heading: "Segments",
              items: [
                {
                  name: "Lending",
                  url: "https://www.leadsquared.com/mobile-crm/",
                },
                {
                  name: "Banking",
                  url: "https://www.leadsquared.com/vendor-onboarding-software/",
                },
                {
                  name: "Credit Unions",
                  url: "https://help.leadsquared.com/mobile-home-builder/",
                },
                {
                  name: "Securities and Trading",
                  url: "https://www.leadsquared.com/outside-sales-crm/",
                },
                {
                  name: "FinTech",
                  url: "https://www.leadsquared.com/outside-sales-crm/",
                },
              ],
            },
          
            {
              heading: "Solutions",
              items: [
                {
                  name: "OPERATIONS",
                  type:"heading",
                  url: "https://www.leadsquared.com/field-force-automation-software/",
                },
                {
                  name: "Lending CRM",
                  url: "https://www.leadsquared.com/collections-crm/",
                },
                {
                  name: "WhatsApp Lending Bot",
                  url: "https://www.leadsquared.com/field-force-tracker/",
                },
                {
                  name: "Debt Recovery Automation",
                  url: "https://www.leadsquared.com/btl-marketing-solution/",
                },
                {
                  name: "Bancassurance Solution",
                  url: "https://www.leadsquared.com/bancassurance-sales/",
                },
                {
                  name: "PAPERLESS ONBOARDING",
                  type:"heading",
                  url: "https://www.leadsquared.com/bancassurance-sales/",
                },
                {
                  name: "e-KYC Solution",
                  url: "https://www.leadsquared.com/bancassurance-sales/",
                },
                {
                  name: "Video KYC Solution",
                  url: "https://www.leadsquared.com/bancassurance-sales/",
                },
              ],
            },
          ],
        },
        {
          name: "Manufacturing",
          avatarUrl: "/images/manufacturing.svg",
          url:"https://www.leadsquared.com/manufacturing-crm-software/",
          innerList:[]
        },
        {
          name: "Automotive",
          avatarUrl: "/images/automotive.svg",
          url:"https://www.leadsquared.com/manufacturing-crm-software/",
          innerList:[]
        },
        {
          name: "Real Estate",
          avatarUrl: "/images/realestate.svg",
          url:"https://www.leadsquared.com/manufacturing-crm-software/",
          innerList:[]
        },
        {
          name: "Tech Marketplaces",
          avatarUrl: "/images/techmarketplace.svg",
          url:"https://www.leadsquared.com/manufacturing-crm-software/",
          innerList:[]
        },
        {
          name: "Others",
          avatarUrl: "/images/others.svg",
          url:"https://www.leadsquared.com/manufacturing-crm-software/",
          innerList:[]
        },
      ],
    },

    {
      headerName: "Company",
      subMenu: [
        {
          name: "Our Story",
          avatarUrl: "/images/our_story.svg",
          url:"https://www.leadsquared.com/manufacturing-crm-software/",
          innerList:[]
        },
        {
          name: "Partners",
          avatarUrl: "/images/partners.svg",
          url:"https://www.leadsquared.com/manufacturing-crm-software/",
          innerList:[]
        },
        {
          name: "News",
          avatarUrl: "/images/news.svg",
          url:"https://www.leadsquared.com/manufacturing-crm-software/",
          innerList:[]
        },
        {
          name: "Careers",
          avatarUrl: "/images/careers.svg",
          url:"https://www.leadsquared.com/manufacturing-crm-software/",
          innerList:[]
        },
        {
          name: "Investor Relations",
          avatarUrl: "/images/investor_relations.svg",
          url:"https://www.leadsquared.com/manufacturing-crm-software/",
          innerList:[]
        },
        {
          name: "Security",
          avatarUrl: "/images/security.svg",
          url:"https://www.leadsquared.com/manufacturing-crm-software/",
          innerList:[]
        },
        {
          name: "Legal",
          avatarUrl: "/images/legal.svg",
          url:"https://www.leadsquared.com/manufacturing-crm-software/",
          innerList:[]
        }
      ]
    },
    {
      headerName: "Customers",
      subMenu: [
        


        {
          name: "View All Success Stories",
          avatarUrl: "/images/customers.svg",
          url:"https://www.leadsquared.com/manufacturing-crm-software/",
          innerList:[]
        },
        {
          name: " Health care",
          avatarUrl: "/images/healthcare_customer.svg",
          url:"https://www.leadsquared.com/manufacturing-crm-software/",
          innerList:[]
        },
        {
          name: "Education",
          avatarUrl: "/images/educationcustomer.svg",
          url:"https://www.leadsquared.com/manufacturing-crm-software/",
          innerList:[]
        },
        {
          name: "Financial Services",
          avatarUrl: "/images/financial_services_c.svg",
          url:"https://www.leadsquared.com/manufacturing-crm-software/",
          innerList:[]
        },
      ]
    },
    
    {
      headerName: "Resources",
      subMenu: [
        


        {
          name: "Customer Stories",
          avatarUrl: "/images/customers.svg",
          url:"https://www.leadsquared.com/manufacturing-crm-software/",
          innerList:[]
        },
        {
          name: "Webinars",
          avatarUrl: "/images/webinars.svg",
          url:"https://www.leadsquared.com/manufacturing-crm-software/",
          innerList:[]
        },
        {
          name: "Articles and How-tos",
          avatarUrl: "/images/articles_and_how-tos.svg",
          url:"https://www.leadsquared.com/manufacturing-crm-software/",
          innerList:[]
        },
        {
          name: "Ebooks and Whitepapers",
          avatarUrl: "/images/ebooks_and_whitepapers.svg",
          url:"https://www.leadsquared.com/manufacturing-crm-software/",
          innerList:[]
        },
        {
          name: "Help Documents",
          avatarUrl: "/images/help_documents.svg",
          url:"https://www.leadsquared.com/manufacturing-crm-software/",
          innerList:[]
        },
        {
          name: "API Documents",
          avatarUrl: "/images/API_documents-1.svg",
          url:"https://www.leadsquared.com/manufacturing-crm-software/",
          innerList:[]
        },
        {
          name: "Integrations and Partnerships",
          avatarUrl: "/images/integrations_and_partners-1.svg",
          url:"https://www.leadsquared.com/manufacturing-crm-software/",
          innerList:[]
        },
        {
          name: "Contact Us",
          avatarUrl: "/images/integrations_and_partners.svg",
          url:"https://www.leadsquared.com/manufacturing-crm-software/",
          innerList:[]
        }
      ]
    },
  ];

  return (
    <div className="w-[95%] mx-auto">
      <header className="sm:flex py-[15px] fixed top-0 w-[100%] left-0 bg-white px-[20px] z-[4]">
        <a href="/" className="logo flex items-center">
          <img src="/images/logo.svg" width={"152px"} />
        </a>
        {/* menu */}
        <div onClick={()=>{
          setMobileMenuActive(prev => !prev)
          setSubMenuActive('')
          setActiveHeader(false)
        }
          } 
          className="sm:hidden absolute right-[20px] top-[20px] z-[5]"
          >
          {
            !mobileMenuActive ? 
            <img src="/images/menuinactive.svg" /> 
            :
            <img src="/images/menuactive.svg" />
            }

        </div>
        <div className="sm:flex w-full justify-between items-center sm:pl-[40px]">
          {<div className="">
            <ul className={`${mobileMenuActive ? "block" : "hidden"} sm:flex mt-[15px] sm:mt-0 text-[16px] text-dark gap-[45px]`}>
              {menuItems?.map((el) => {
                return (
                  <>
                    <li
                      className="cursor-pointer py-[10px] sm:py-0"
                      onClick={() => {
                        setActiveHeader(el.headerName);
                        
                      }}>
                      {el.headerName}
                    </li>
                  </>
                );
              })}
            </ul>
            {activeHeader && (
              <div
                className="fixed h-[100%] left-[0] top-[70px] bg-[#00000060] w-full z-[4]"
                onMouseEnter={() => setSubMenuActive("")}
                onClick={() => {
                  setActiveHeader("");
                }}>
                <div className="flex h-[100%]">
                  <div
                    className="w-[100%] sm:w-[30%] pl-[20px] sm:pl-[80px] py-[50px] bg-white relative overflow-auto"
                    onClick={(e) => e.stopPropagation()}>
                    <span
                      onClick={() => {
                        setActiveHeader("");
                        setSubMenuActive('')
                      }}
                      className="absolute right-[25px] z-[4] top-[25px] cursor-pointer">
                      <img
                        src="/images/close_X.svg"
                        className="w-[15px] h-[15px]"
                      />
                    </span>
                    <h4 className="text-[#001b60] text-[16px] font-semibold uppercase">
                      {activeHeader}
                    </h4>
                 
                    <ul className="text-[#354f64] text-[15px]">
                      {menuItems
                        ?.filter((ele) => ele.headerName === activeHeader)?.[0]
                        ?.subMenu?.map((subMenu) => {
                          return (
                            <>
                              <li
                                className="py-[15px] flex justify-between items-center capitalize pr-[40px]"
                                onMouseEnter={() =>
                                  setSubMenuActive(subMenu.name)
                                }
                                onClick={() =>
                                  setSubMenuActive(subMenu.name)
                                }
                                >
                                <span className="flex items-center cursor-pointer">
                                  <span
                                    className="p-[8px] w-[35px] inline-block rounded-[50px] mr-3"
                                    style={{
                                      "box-shadow":
                                        "rgba(24, 24, 24, 0.12) 4px 8px 15px",
                                    }}>
                                    <img src={subMenu?.avatarUrl} />
                                  </span>
                                  {subMenu.name}
                                </span>
                                <span>
                                  <img
                                    src="/images/mega-arrow.svg"
                                    className={`w-[25px] h-[25px] relative ${
                                      subMenu.name === subMenuActive
                                        ? "scale-1 left-[0]"
                                        : "scale-0 left-[-12px]"
                                    } transition-all duration-[0.5s]`}
                                  />
                                </span>
                              </li>
                            </>
                          );
                        })}
                    </ul>
                  </div>
                  {menuItems
                    ?.filter((ele) => ele.headerName === activeHeader)?.[0]
                    ?.subMenu?.filter((el) => el.name === subMenuActive)?.[0]
                    ?.innerList?.length > 0 && (
                    <div
                      className="w-[100%] sm:w-[60%] pl-[20px] sm:pl-[80px] py-[50px] bg-[#f0f5ff] customScroll overflow-y-auto absolute sm:relative z-[2] max-h-[100vh] sm:max-h-[auto]"
                      onClick={(e) => e.stopPropagation()}>
                      <div className="block sm:flex">
                        {menuItems
                          ?.filter(
                            (ele) => ele.headerName === activeHeader
                          )?.[0]
                          ?.subMenu?.filter(
                            (el) => el.name === subMenuActive
                          )?.[0]
                          ?.innerList?.map((innerList) => {
                            return (
                              <>
                                <div class="w-[50%] overflow-y-auto">
                                  <h4 className="text-[#001b60] text-[16px] font-semibold uppercase mb-4">
                                    {innerList.heading}
                                  </h4>
                                  <ul className="text-[#354f64] text-[15px]">
                                    {innerList.items?.map((items) => {
                                      return (
                                        <li className={`py-[9px] flex items-center capitalize ${items.type === "heading" && "text-[#527a99] text-[13px] my-[5px]"} `}
                                        
                                        >
                                          <a href={items.url}>{items.name}</a>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              </>
                            );
                          })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>}
          <div className="sm:flex items-center">
            <a href="tel:+918046971075" className={`${mobileMenuActive ? "flex" : "hidden"} sm:flex items-center`}>
              <img
                src="/images/phoneHeader.svg"
                className="w-[16px] h-[16px] mr-[8px]"
                alt="phone"
              />
              080-46971075
            </a>
            <a href="https://www.leadsquared.com/?s" className="absolute sm:static top-[20px] right-[45px] flex items-center mr-[6px] ml-[19px]">
              <img
                src="/images/searchHeader.svg"
                className="w-[24px] sm:w-[16px] h-[22px] sm:h-[16px] mr-[8px]"
                alt="phone"
              />
            </a>
            <a href="https://www.leadsquared.com/" className="absolute sm:static top-[20px] right-[85px] flex items-center">
              <img
                src="/images/globe.svg"
                className="w-[24px] sm:w-[16px] h-[22px] sm:h-[16px] mr-[8px]"
                alt="phone"
              />
            </a>
            <a href="https://login.leadsquared.com/" className={`${mobileMenuActive ? "flex" : "hidden"} sm:flex items-center mr-[20px]`}>
              Login
            </a>
            
            <a href="https://www.leadsquared.com/book-demo/" className={`${mobileMenuActive ? "flex" : "hidden"} sm:flex`}>
              <button className="btnPrimaryHeader text-[14px] mt-[12px] sm:mt-0" type="button">
                Book a demo
              </button>
            </a>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Index;
