import axios from "axios";

export const getData = async (endpoint) => {
  try {
    const response = await axios.get(
      
      `https://api.partnerchampion.com/public/v1/${endpoint}`,
      {
        headers: {
          "x-partnerchampion-key":
            "kbUtYR1KxpcFbxKbzZ5WWhFJIa19TYrjA3x2WzyKCVw6AG0loNcRRcccUnlFX1HmpDob",
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjIsImVtYWlsX2FkZHJlc3MiOiJqYW5hbmlAcGFydG5lcmNoYW1waW9uLmNvbSIsImFjY291bnRJZCI6NCwiaWF0IjoxNzE4ODYyMTMwLCJleHAiOjE3MTg5NDg1MzB9.1f57a4qwV0NeXwK4endYROCQdkDBd36tWROn5Iz17B8",
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};




export const postData = async(endpoint,body ) => {
  const config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `https://api.partnerchampion.com/public/v1/${endpoint}`,
    headers: { 
      'x-partnerchampion-key': 'kbUtYR1KxpcFbxKbzZ5WWhFJIa19TYrjA3x2WzyKCVw6AG0loNcRRcccUnlFX1HmpDob', 
      'Content-Type': 'application/json', 
      'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjIsImVtYWlsX2FkZHJlc3MiOiJqYW5hbmlAcGFydG5lcmNoYW1waW9uLmNvbSIsImFjY291bnRJZCI6NCwiaWF0IjoxNzE4ODYyMTMwLCJleHAiOjE3MTg5NDg1MzB9.1f57a4qwV0NeXwK4endYROCQdkDBd36tWROn5Iz17B8'
    },
    data: JSON.stringify(body)
  };

  return axios.request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}