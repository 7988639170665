import React, { useEffect, useState } from "react";
import { getData } from "../../services/Api";
import { useNavigate } from "react-router-dom";

function Index({ currentPageHeading }) {
  const [showSearchResult, setShowSearchResult] = useState();

  const [searchList, setSearchList] = useState([]);

  const fetchSearchResult = async (val) => {
    try {
      const apiData = await getData(`partner-search?search_text=${val}`);
      setSearchList(apiData?.data || []);
    } catch (error) {}
  };

  const handleSearch = (e) => {
    if (e.target.value !== "") {
      setShowSearchResult(true);
    } else {
      setShowSearchResult(false);
    }
    fetchSearchResult(e?.target?.value);
  };
  const navigate = useNavigate();
  return (
    <>
      <div className="mb-[40px]">
        <div className="block sm:flex items-center justify-center py-5">
          <h1 className="font-bold text-[35px]">Find a partner</h1>
          <div className="w-full sm:w-[40%] sm:pl-[50px]">
            <div className="relative w-full">
              <input
                className="border border-primary border-solid bg-white py-2 pl-2 w-full rounded-[4px]"
                type="text"
                placeholder="Search partners..."
                onKeyUp={handleSearch}
              />
              <button
                className="rounded-[4px] btnPrimary absolute right-0 top-0 h-[100%] w-[100px] text-center"
                type="button"
                onClick={
                  showSearchResult ? () => setShowSearchResult(false) : () => {}
                }>
                {showSearchResult ? (
                  <img src="/images/close.svg" className="mx-auto" />
                ) : (
                  <img src="/images/searchIcon.svg" className="mx-auto" />
                )}
              </button>
            </div>
            {showSearchResult && (
              <ul className="mt-1 bg-white border rounded-[4px] border-[#C8DCEC] text-left px-3 py-2 text-[16px] text-[#6C6F75] customScroll max-h-[140px] overflow-y-auto">
              {searchList?.length > 0 ? (
                <>
                  {searchList?.map((el) => (
                    <li
                      className="mb-2 cursor-pointer"
                      onClick={()=>window.location.href =`/partner/${(el?.title)?.toLowerCase().trim().replace(' ','_')}`}>
                      {el?.title}
                    </li>
                  ))}
                </>
              ) : (
                <li className="mb-0 opacity-[0.6]">No records found</li>
              )}
            </ul>
            )}
          </div>
        </div>
        {/* breadcrumbs */}
        <div className="container mx-auto">
          <ul className="flex items-center mt-[40px] border-b border-[#bbc3d9] border-solid pb-2 text-[14px] text-[#4f6a82]">
            <li className="cursor-pointer text-[#000]" onClick={()=>navigate('/')}>Partners directory</li>
            <img
              src="/images/breadcrumbArrow.svg"
              alt=""
              width={"14px"}
              style={{ margin: "0 7px",opacity: "0.4" }}
            />
            <li className="text-[#7d7d7d]">{currentPageHeading}</li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Index;
