import React, { useEffect, useState } from "react";
import SearchBanner from "../../components/searchBoxFlexed";
import PartnerGrid from "../../components/partnerGrid";
import Testimonial from "../../components/slider";
import ReviewBox from "../../components/reviews";
import StarRating from "../../components/common/ratingStar";
import { useSearchParams } from "react-router-dom";
import { getData, postData } from "../../services/Api";

function Index() {
  const staticArr = [
    {
      name: "tester",
    },
    {
      name: "tester1",
    },
  ];

  const pathName = window.location.pathname;
  const [partnerDetailData, setPartnerDetailData] = useState(null);

  const fetchPartnerDataFromApi = async () => {
    try {
      const apiData = await getData(`partner/view/${pathName.split('/').pop()}`);
      setPartnerDetailData(apiData?.data?.[0]);
    } catch (error) { }
  };
  useEffect(() => {
    fetchPartnerDataFromApi();
  }, []);

  useEffect(()=>{
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Optional: smooth scroll behavior
    });
  },[])
  const[success,setSuccess] = useState();
  const[error,setError] = useState(false);
  const[emailerror,setEmailError] = useState(false);
  const[descriptionerror,setdescriptionError] = useState(false);
  const [formData, setFormData] = useState({
    contact_email_address: "",
    contact_phone_number: "",
    description: "",
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      "name": `${prevData.firstname} ${prevData.lastname}`,
      "user_infoId": 2,
    }));
  };

  const handleSubmit = async (e) => {
    delete formData.firstname;
    delete formData.lastname;
    e.preventDefault();
    if(!(formData.contact_email_address && formData.description)){
      if(!formData.contact_email_address){
        setEmailError('The field is required')
      }
      else{
        setEmailError(false)
      }
      if(!formData.description){
        setdescriptionError('The field is required')
      }
      else{
        setdescriptionError(false)
      }
      
      setError('All fields are required')
      setTimeout(() => {
        setError(false)
      }, 2000);
      return
    }
    try {
      const apiData = await postData("/lead", formData);
      // Handle response from API if needed
      console.log(apiData);
      setSuccess(true)
      setEmailError(false)
      setdescriptionError(false)
      setTimeout(() => {
        setSuccess(false)
        setFormData(
          {
            firstname:"",
            lastname:"",
            contact_email_address: "",
    contact_phone_number: "",
    description: "",
          }
        )
      }, 2000);

      // Close the modal after successful submission
    } catch (error) {
      // Handle error if submission fails
      console.error("Error submitting data:", error);
    }
  };

  return (
    <div className="text-center pt-[100px]">
      <div class="w-[85%] mx-auto">
        <SearchBanner currentPageHeading={partnerDetailData?.name} />
        {/*  */}
        <div class="container mx-auto">
          <div className="block sm:flex">
            <div className="w-full sm:w-[30%] pr-[70px]">
              <h1 className="font-bold text-[24px] text-left mb-[30px] mt-[20px]">
                {partnerDetailData?.title}
              </h1>
              <ul className="text-[16px]">
                {partnerDetailData?.phone_number && <span className="flex mb-2">
                  <img src="/images/phone.svg" alt="phoneIcon" />
                  <span className="pl-2">{partnerDetailData?.phone_number}</span>
                </span>}
                {partnerDetailData?.email_address && <span className="flex mb-2">
                  <img src="/images/mailIcon.svg" alt="phoneIcon" />
                  <span className="pl-2">{partnerDetailData?.email_address}</span>
                </span>}
                {partnerDetailData?.website_url && <span className="flex mb-2">
                  <img src="/images/websiteIcon.svg" alt="phoneIcon" />
                  <a className="pl-2 cursor-pointer" href={partnerDetailData?.website_url}>{partnerDetailData?.website_url}</a>
                </span>}
                {partnerDetailData?.address_line_1 && <span className="flex mb-2 text-left items-baseline mt-6">
                  <img src="/images/locationIcon.svg" alt="phoneIcon" />
                  <span className="pl-2">
                    {partnerDetailData?.address_line_1}
                    {partnerDetailData?.address_line_2}
                    <br /> {partnerDetailData?.country}
                  </span>
                </span>}
              </ul>
            </div>
            <div className="w-full sm:w-[70%] pl-0 sm:pl-[30px]  mt-[20px]">
              {partnerDetailData?.avatar && (
                <div>
                  <img
                    src={partnerDetailData?.avatar}
                    className="object-contain object-left h-[70px]"
                    width={"250px"}
                    height={"80px"}

                  />
                </div>
              )}
              <div>
                <h1 className="font-bold text-[32px] text-left my-[20px] capitalize">
                  {partnerDetailData?.name}
                </h1>
                <p className="text-left text-[#666] text-[16px] partnerDescription leading-[1.8em]" dangerouslySetInnerHTML={{ __html: partnerDetailData?.description }}>{ }</p>
              </div>
              {/* <div className="mt-[50px] text-left">
                <h1 className="font-bold text-[20px] text-left my-[40px]">
                  Reviews
                </h1>
                <div class="flex justify-between border-b border-[#DDDDDD] border-solid pb-[40px]">
                  <div className="text-left">
                    <div className="flex items-center">
                      <span className="font-semibold pr-2">5</span>
                      <StarRating val={5} />
                    </div>
                    <p className="text-[#676767] text-[14px]">18 ratings</p>
                  </div>
                  <div>
                    <button className="bg-[#fff] text-[#000] px-[24px] border font-semibold border-[#000] border-solid py-[9px] text-[12px] rounded-[3px] uppercase">
                      Write review
                    </button>
                  </div>
                </div>
                reviews list
                {staticArr?.map((el) => {
                  return (
                    <>
                      <ReviewBox name={el.name} />
                    </>
                  );
                })}
                <a href="/" className="text-[#0040DF] text-left font-semibold">
                  Show all reviews
                </a>
              </div> */}
              <div className="mt-[50px] text-left">
                <h1 className="font-bold text-[20px] text-left my-[40px]">
                  Contact Partner
                </h1>
              </div>
              <div >
                <form className="flex flex-wrap" action="" onSubmit={handleSubmit}>
                  <div className="w-full sm:w-[50%] sm:pr-[30px] relative">
                    <input
                      className="w-full border border-[#9EB1E8] border-solid p-[15px] rounded-[4px] mb-[40px]"
                      placeholder="First Name"
                      id="firstname"
                      name="firstname"
                      value={formData.firstname}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-full sm:w-[50%] relative">
                    <input
                      className="w-full border border-[#9EB1E8] border-solid p-[15px] rounded-[4px] mb-[40px]"
                      placeholder="Last Name"
                      id="lastname"
                      name="lastname"
                      value={formData.lastname}
                      onChange={handleChange}
                    />
                    
                  </div>
                  <div className="w-full sm:w-[50%] sm:pr-[30px] relative">
                    <input
                      className="w-full border border-[#9EB1E8] border-solid p-[15px] rounded-[4px] mb-[40px]"
                      placeholder="Email*"
                      name="contact_email_address"
                      id="contact_email_address"
                      value={formData.contact_email_address}
                      onChange={handleChange}
                    />
                    {emailerror &&<p className="absolute bottom-[15px]  text-[#ff0000]">{emailerror || 'The field is invalid'}</p>}
                  </div>
                  <div className="w-full sm:w-[50%]">
                    <input
                    type="number"
                  
                      className="hideStepper w-full border border-[#9EB1E8] border-solid p-[15px] rounded-[4px] mb-[40px]"
                      placeholder="Phone Number"
                      name="contact_phone_number"
                      id="contact_phone_number"
                      value={formData.contact_phone_number}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-[100%] mb-[40px] relative">
                    <textarea
                      className="w-full border border-[#9EB1E8] border-solid p-[15px] rounded-[4px] mb-[8px] h-[140px]"
                      placeholder="Description*"
                      name="description"
                      id="description"
                      value={formData.description}
                      onChange={handleChange}
                      maxLength={500}
                    />
                    {descriptionerror &&<p className="absolute bottom-[15px] text-[#ff0000]">{descriptionerror || 'The field is invalid'}</p>}
                    <p className="text-[#939393] text-left text-[14px] pt-1">
                      More than 500 characters are not allowed
                    </p>
                  </div>
                  <div className="w-[100%] text-right">
                    <button type="submit" className="btnPrimary min-w-[150px]">SEND</button>
                  </div>
                  {
                    success &&
                     <p className="successAlert">
                      Submitted successfully. We will get back to you soon
                      </p>
                  }
                  {
                    error &&
                    <p className="errorAlert">
                      {error || 'something went wrong'}
                    </p>
                  }
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#030F2D] px-5 py-[100px] mt-[120px]">
        <div className="container mx-auto text-center block sm:flex justify-evenly">
          <h4 className="text-left font-semibold text-[39px] text-white">
            Want to see
            <br /> LeadSquared in action?
          </h4>
          <a href="https://www.leadsquared.com/book-demo/" >
            <button className="text-dark bg-white px-[30px] py-[9px] text-[14px] sm:text-[18px] rounded-[3px] mt-8">
              Book a Personalized Demo Now
            </button>
          </a>

        </div>
      </div>
    </div>
  );
}

export default Index;
